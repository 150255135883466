import React from 'react'
import QuemSomos from './quem-somos'
import '../src/i18n'
import { useRouter } from 'next/router'
import Link from 'next/link'
import { handleUserSession } from '@/utils/fetchDataAxios'
import { fetchRequestPix } from '@/utils/fetchDataAxios'
import UserContext from '@/context/UserContext'
import Head from 'next/head'

const Home = ({ envs }: any) => {
  const [userInfo, setUserInfo] = React.useState()
  const [loggedIn, setLoggedIn] = React.useState(false)
  const router = useRouter()
  const { locale, locales, push } = useRouter();



  return <UserContext.Provider
    value={{
      userInfo: [userInfo, setUserInfo],
      loggedIn: [loggedIn, setLoggedIn],
      locale
    }}
  >
    <Head>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${envs.NEXT_PUBLIC_ID_GOOGLE_ANALYTIC}`}></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${envs.NEXT_PUBLIC_ID_GOOGLE_ANALYTIC}', { page_path: window.location.pathname});
          `
        }}
      />
    </Head>
    <QuemSomos envs={envs} />

  </UserContext.Provider>
}

export async function getServerSideProps() {
  // Obtendo o valor da variável de ambiente usando process.env

  const {
    NEXT_PUBLIC_PROJETO_URL
    , NEXT_PUBLIC_PROJETO_ID_URL
    , NEXT_PUBLIC_GET_USER_INFO,
    NEXT_PUBLIC_GET_TRANSACTIONS
    , NEXT_PUBLIC_GERAR_PIX,
    NEXT_PUBLIC_COMPRAR_TOKEN
    , NEXT_PUBLIC_REQUEST_LOGIN,
    NEXT_PUBLIC_CADASTRAR_USER
    , NEXT_PUBLIC_GET_USER_CADASTRO,
    NEXT_PUBLIC_AUTH_LOGIN
    , NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO,
    NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO
    , NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO,
    NEXT_PUBLIC_CRIAR_PROJETO
    , NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO,
    NEXT_PUBLIC_VERIFICATION_USER
    , NEXT_PUBLIC_SEND_EMAIL_FINISHED,
    NEXT_PUBLIC_SEND_EMAIL_APPROVED
    , NEXT_PUBLIC_SEND_EMAIL_REJECTED,
    NEXT_PUBLIC_VERIFICATION_ID_METAMAP, NEXT_PUBLIC_ID_GOOGLE_ANALYTIC } = process.env
  const envs = {
    NEXT_PUBLIC_AUTH_LOGIN: NEXT_PUBLIC_AUTH_LOGIN,
    NEXT_PUBLIC_CADASTRAR_USER: NEXT_PUBLIC_CADASTRAR_USER,
    NEXT_PUBLIC_COMPRAR_TOKEN: NEXT_PUBLIC_COMPRAR_TOKEN,
    NEXT_PUBLIC_CRIAR_PROJETO: NEXT_PUBLIC_CRIAR_PROJETO,
    NEXT_PUBLIC_GERAR_PIX: NEXT_PUBLIC_GERAR_PIX,
    NEXT_PUBLIC_GET_TRANSACTIONS: NEXT_PUBLIC_GET_TRANSACTIONS,
    NEXT_PUBLIC_GET_USER_CADASTRO: NEXT_PUBLIC_GET_USER_CADASTRO,
    NEXT_PUBLIC_GET_USER_INFO: NEXT_PUBLIC_GET_USER_INFO,
    NEXT_PUBLIC_PROJETO_ID_URL: NEXT_PUBLIC_PROJETO_ID_URL,
    NEXT_PUBLIC_PROJETO_URL: NEXT_PUBLIC_PROJETO_URL,
    NEXT_PUBLIC_REQUEST_LOGIN: NEXT_PUBLIC_REQUEST_LOGIN,
    NEXT_PUBLIC_SEND_EMAIL_APPROVED: NEXT_PUBLIC_SEND_EMAIL_APPROVED,
    NEXT_PUBLIC_SEND_EMAIL_FINISHED: NEXT_PUBLIC_SEND_EMAIL_FINISHED,
    NEXT_PUBLIC_SEND_EMAIL_REJECTED: NEXT_PUBLIC_SEND_EMAIL_REJECTED,
    NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO: NEXT_PUBLIC_UPLOAD_BACKGROUND_PHOTO,
    NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO: NEXT_PUBLIC_UPLOAD_BACKGROUND_PROJETO,
    NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO: NEXT_PUBLIC_UPLOAD_DOCUMENTS_PROJETO,
    NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO: NEXT_PUBLIC_UPLOAD_PROFILE_PHOTO,
    NEXT_PUBLIC_VERIFICATION_ID_METAMAP: NEXT_PUBLIC_VERIFICATION_ID_METAMAP,
    NEXT_PUBLIC_VERIFICATION_USER: NEXT_PUBLIC_VERIFICATION_USER,
    NEXT_PUBLIC_ID_GOOGLE_ANALYTIC: NEXT_PUBLIC_ID_GOOGLE_ANALYTIC
  }


  return {
    props: {
      envs
    },
  };
}

export default Home